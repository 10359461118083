.box {
	position: relative;
}

.icon {
	color: var(--light);
	display: flex;
	cursor: pointer;
}

.icon:hover {
	color: var(--auth-primary);
}

.tooltip {
	position: absolute;
	top: -32px;
	left: 50%;
	transform: translateX(-50%);
	background-color: var(--auth-primary);
	color: var(--light);
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 500;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	color: white;
	transition: all 0.3s ease-in-out;
}

.tooltip:after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: var(--auth-primary) transparent transparent transparent;
}

.card {
	width: 100%;
	padding-inline: 40px 10px;
	max-width: 600px;
	margin-inline: auto;
	text-align: center;
}

.title {
	margin-bottom: 15px;
}

.reminder {
	margin-bottom: 50px;
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	font-weight: 600;
}

.button {
	width: 100%;
	border-radius: 50px;
	margin-bottom: 10px;
}

.form {
	max-width: 458px;
	margin-inline: auto;
}

.form div:first-child {
	display: flex;
	column-gap: 10px;
	justify-content: center;
}

.form input {
	margin-bottom: 50px;
	width: calc(100% / 6);
	height: 65px;
	outline: none;
	border-radius: 10px;
	text-align: center;
	border: 1px solid var(--input-border);
	font-size: 25px;
	filter: drop-shadow(0px 4px 7px rgba(222, 194, 255, 0.25));
}

.expired {
	text-align: center;
}

.expired h4 {
	margin-block: 10px;
}

.expired p {
	font-size: 14px;
	color: #5e5e5e;
	margin-bottom: 20px;
}

.buttonLogin {
	width: 100%;
	max-width: 300px;
	border-radius: 55px;
	margin-block: 10px;
}

@media screen and (max-width: 991px) {
	.card {
		padding-left: 0;
	}
}

@media screen and (max-width: 575px) {
	.form input {
		height: 55px;
	}
}
@media screen and (max-width: 400px) {
	.form input {
		height: 45px;
	}
}

.errorBox {
	color: var(--error);
	padding: 10px;
	background-color: rgba(255, 0, 0, 0.13);
	border: 1px solid rgba(255, 0, 0, 0.35);
	border-radius: 20px;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 30px;
	text-align: center;
	animation: smallToBig 0.2s ease-in-out;
	height: 100%;
}

@keyframes smallToBig {
	0% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.card {
  background: url(../../../assets/images/share-modal.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 25px;
  padding-bottom: 34px;
  padding-top: 70px;
}

.profileBadge {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-inline: auto;
  border: 3px solid #d9e4f1;
  top: -50px;
}

.glass {
  background: rgba(255, 255, 255, 0.21);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.8px);
  -webkit-backdrop-filter: blur(5.8px);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-inline: 60px;
  padding-inline: 20px;
  padding-bottom: 20px;
  padding-top: 70px;
  position: relative;
}

.info {
  color: #fff;
  font-weight: 400;
}

.superTitle {
  text-align: left;
  margin-bottom: 10px;
  display: grid;
  overflow: hidden;
  grid-template-columns: 145px 1fr;
}

.superTitle p {
  white-space: nowrap;
}

.usernameIt {
  color: #5ca8ff;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 500;
}

.whitePaper {
  background: #fff;
  margin-inline: 60px;
  padding-inline: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.qrRow {
  display: flex;
  justify-content: center;
}

.qrOuterContainer {
  position: relative;
  border-radius: 8px;
  margin-bottom: 20px;
  max-width: 180px;
  max-height: 170px;
}

.qrInnerContainer {
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  padding: 21px 20px 0px 20px;
}

.qrCode {
  width: 100%;
  height: 100%;
}
.social {
  margin-bottom: 20px;
}

.socialTitle {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}

.socialIcons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.socialIconsWrapper {
  background: rgba(238, 76, 151, 0.1);
  width: 38px;
  height: 38px;
  transition: all 0.3s ease-in;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialIconsWrapper:hover {
  background: rgba(123, 182, 228, 0.583) !important;
}

.socialIconsWrapper img {
  width: 24px;
  height: 24px;
}

.referralLink {
  padding-bottom: 24px;
}

/* -------- CopyInput -------- */
.copyInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eff6ff;
  border: 1px solid #d9e4f1;
  padding: 16px;
  border-radius: 8px;
}

.copyBtn {
  cursor: pointer;
  justify-self: flex-end;
  position: relative;
}

.copyBtn:hover,
.copyBtn:hover svg path {
  stroke: #d5c7c7;
  transition: all 0.3s ease;
}

.toCopy {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #5d6e82;
  font-size: 14px;
  font-weight: 500;
}

.label {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}

.iconText {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}

.copyTooltip {
  position: absolute;
  top: -144%;
  left: -29px;
  background-color: #000000;
  padding: 5px 10px;
  border-radius: 8px;
  color: var(--light);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.copyTooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}

.show {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 395px) {
  .toCopy,
  .usernameIt {
    max-width: 15ch;
    text-overflow: ellipsis;
  }

  .superTitle {
    grid-template-columns: 1fr;
  }

  .socialIcons {
    justify-content: flex-start;
    gap: 10px;
  }
}

.borderWarnSide {
	height: 100%;
	width: 10px;
	position: fixed;
	background: repeating-linear-gradient(
			45deg,
			transparent,
			transparent 15px,
			#0f0f0d 15px,
			#0f0f0d 30px
		),
		linear-gradient(to bottom, #f9cc0f, #f9cc0f);
	z-index: 99998;
}

.mode {
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 4px;
	padding: 0 10px;
	color: var(--error);
	width: 280px;
	font-weight: 800;
	margin-inline: auto;
	height: 25px;
	margin-top: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: colorBrightness 2s infinite;
}

.topBar,
.bottomBar {
	height: 30px;
	width: 100%;
	background: repeating-linear-gradient(
			45deg,
			transparent,
			transparent 15px,
			#0f0f0d 15px,
			#0f0f0d 30px
		),
		linear-gradient(to bottom, #f9cc0f, #f9cc0f);
	position: fixed;
	z-index: 99999;
}

.bottomBar {
	bottom: 0;
}

/*  animation color brightness */
@keyframes colorBrightness {
	0% {
		filter: brightness(1.3);
	}
	50% {
		filter: brightness(1);
	}
	100% {
		filter: brightness(1.3);
	}
}

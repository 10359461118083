.iconBox {
	height: 36px;
	width: 36px;
	border-radius: 50%;
	backdrop-filter: blur(10.5px);
	-webkit-backdrop-filter: blur(10.5px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.iconBox:hover {
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.05);
}

@media screen and (max-width: 767px) {
	.logout {
		display: none;
	}
}

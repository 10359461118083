.box {
	position: relative;
	height: 100%;
	width: 100%;
	max-height: 830px;
	max-width: 670px;
	margin-inline: auto;
	overflow: hidden;
	border-radius: 30px;
}

.coverText {
	position: absolute;
	text-align: center;
	width: 100%;
	bottom: 100px;
	z-index: 10;
	color: var(--light);
	text-transform: capitalize;
	white-space: break-spaces;
}
.coverText h4 {
	max-width: 450px;
	margin-inline: auto;
	font-weight: 600;
	margin-bottom: 21px;
	padding: 0 1vw;
}
.coverText p {
	max-width: 490px;
	margin-inline: auto;
	font-weight: 500;
	padding: 0 1vw;
}

.tabs {
	display: flex;
	column-gap: 6px;
	position: absolute;
	z-index: 10;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
}

.tab,
.tabActive {
	width: 52px;
	height: 4px;
	border-radius: 65px;
	background-color: white;
	opacity: 0.16;
	transition: all 0.5s ease;
	cursor: pointer;
}

.tab:hover {
	background-color: #d9d9d9;
	opacity: 0.5;
}

.tabActive {
	background-color: #d9d9d9;
	opacity: 1;
}

.slide {
	white-space: nowrap;
	transition: all 0.5s ease;
	height: 100%;
}

.slide .item {
	width: 100%;
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 100%;
}

.item img {
	max-height: 100%;
	max-width: 100%;
}

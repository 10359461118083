.waitResend,
.haveNotReceived {
	font-size: 14px;
	color: #5e5e5e;
}

.haveNotReceived {
	color: #5e5e5e;
}

.haveNotReceived > span {
	color: var(--auth-primary);
	cursor: pointer;
}

.menu,
.opened {
	height: calc(100dvh - var(--header-main));
	position: fixed;
	top: 90px;
	right: 0;
	/* background-color: red; */
	backdrop-filter: blur(24px);
	-webkit-backdrop-filter: blur(24px);
	/* box shadow inset solo sul lato destro */
	box-shadow: inset 1000px 0px 10px -10px rgba(0, 2, 10, 0.6);
	width: 100%;
	max-width: 320px;
	z-index: 99;
	transform: translateX(100%);
	transition: all 0.5s ease;
	padding: 20px;
}

.opened {
	transform: translateX(0);
	overflow-y: auto;
	overflow: overlay;
}

.dropdown {
	z-index: 3;
	top: 63px;
	left: calc(280px / 2 * -1);
	width: 280px;
	color: #d4d4d4;
	padding: 23px 19px 21px;
	border-radius: 8px;
	transition: all 0.3s ease;
	animation: var(--fadeInAndScrollUp) 0.5s;
}

.dropdown ul {
	display: flex;
	flex-direction: column;
	row-gap: 23px;
	list-style: none;
	font-size: 16px;
	font-weight: 500;
}

.dropdown ul li:hover {
	color: #fff;
	cursor: pointer;
}

.responsiveList li.active {
	color: #fff;
	position: relative;
	transition: all 0.3s ease;
}

.responsiveList .active::before {
	position: absolute;
	transition: all 0.3s ease;
	content: '';
	display: block;
	height: 2px;
	width: 120px;
	left: 0;
	bottom: -5px;
	background-color: var(--active);
	border-radius: 0;
	margin-top: 0;
	animation: widthanimation 0.3s ease;
}

.notifications {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	padding-bottom: 15px;
	display: flex;
	justify-content: flex-end;
	gap: 20px;
}

@keyframes widthanimation {
	0% {
		width: 0;
	}
	100% {
		width: 120px;
	}
}

.security {
	margin-block: 45px 55px;
	margin-bottom: 30px;
	animation: var(--fadeInAndScrollUp) 0.5s ease;
}

.box {
	padding: 26px 30px 31px 30px;
	border-radius: 8px;
	border: 1px solid #ededed;
}

.subtitle {
	font-size: 15px;
	color: var(--auth-text-secondary);
	margin-bottom: 30px;
	font-weight: 500;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
	min-height: 39px;
}

.header h5 {
	font-weight: 600;
	margin-bottom: 6px;
}
.reset {
	width: 130px;
	padding: 10px 20px;
	border-radius: 6px;
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	border: 1px solid #ededed;
	transition: all 0.3s ease;
	color: #55585f;
}

.reset:hover {
	background-color: var(--auth-primary);
	cursor: pointer;
	color: var(--light);
}

.info {
	color: #5e5e5e;
	font-weight: 500;
}

.grid {
	display: flex;
	column-gap: 10px;
	align-items: center;
}

.active {
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.divider {
	margin-block: 35px 0;
	width: 100%;
	height: 1px;
	background-color: #ededed;
}

.label {
	color: #5e5e5e;
	font-weight: 500;
	margin-block: 32px 30px;
}

.title {
	font-weight: 600;
	margin-bottom: 10px;
}

.codeBox {
	max-width: 482px;
}

.buttons {
	margin-left: auto;
	max-width: 183px;
}

.verifyButton {
	width: 100%;
	border-radius: 55px;
}

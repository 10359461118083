.security {
	margin-block: 45px 55px;
	margin-bottom: 30px;
	animation: var(--fadeInAndScrollUp) 0.5s ease;
}

.box {
	padding: 26px 30px 31px 30px;
	border-radius: 8px;
	border: 1px solid #ededed;
}

.subtitle {
	font-size: 15px;
	color: var(--auth-text-secondary);
	margin-bottom: 30px;
	font-weight: 500;
}

.box h5 {
	font-weight: 600;
	margin-bottom: 6px;
}

.btnBox {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.button {
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100px;
	flex-shrink: 0;
	padding: 10px 15px;
	height: 34px;
	border-radius: 39px;
}

.key {
	color: var(--auth-text-secondary);
	font-weight: 500;
	margin-right: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

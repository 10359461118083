.sso {
	min-height: 100dvh;
	width: 100%;
	background-image: url('../../assets/images/hero-bg.webp');
	background-image: cover;
	background-position: top left;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	padding-block: 50px;
}

.card {
	width: 100%;
	padding-inline: 40px 10px;
	max-width: 600px;
	margin-inline: auto;
}

.title {
	margin-bottom: 15px;
}

.reminder {
	margin-bottom: 50px;
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	font-weight: 600;
}

.list {
	max-height: 360px;
	padding-right: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
}

.list::before {
	content: '';
	display: block;
	height: 20px;
	position: sticky;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(
		180deg,
		rgb(255, 255, 255) 0%,
		rgba(255, 255, 255, 0.562) 100%
	);
}

.list::after {
	content: '';
	display: block;
	height: 20px;
	position: sticky;
	bottom: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(
		0deg,
		rgb(255, 255, 255) 0%,
		rgba(255, 255, 255, 0.562) 100%
	);
}

.type,
.selected {
	border-radius: 6px;
	border: 1px solid rgba(234, 234, 234, 0.5);
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
	padding: 24px 30px;
	font-size: var(--font-size-md);
	margin-block: 25px 10px;
	display: grid;
	grid-template-columns: 50px 1fr;
	column-gap: 24px;
	position: relative;
	overflow: hidden;
	transition: all 0.5s ease;
	position: relative;
	align-items: center;
	font-weight: 600;
	font-size: 18px;
}

.type svg,
.selected svg {
	transition: all 0.5s ease;
	color: var(--auth-text-secondary);
}

.type:hover,
.type:hover svg,
.selected:hover,
.selected:hover svg {
	border-color: var(--auth-primary-hover);
	color: var(--auth-primary-hover);
	cursor: pointer;
}

.type:first-child,
.selected:first-child {
	margin-top: 0;
}

.selected,
.selected svg {
	border-color: var(--auth-primary-hover);
	color: var(--auth-primary-hover);
}

.selected {
	background: linear-gradient(
		to left,
		rgba(79, 125, 255, 0.03) 0%,
		rgba(79, 125, 255, 0.05) 50%,
		transparent 50%,
		transparent 100%
	);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all 0.5s ease;
}

.information h5 {
	font-weight: 600;
}

.information .text {
	font-size: var(--font-size-base);
	color: var(--auth-text-secondary);
	margin-top: 5px;
}

.button {
	margin-top: 32px;
	width: 100%;
	border-radius: 50px;
}

.name {
	display: flex;
	gap: 19px;
	align-items: center;
	font-weight: 600;
	font-size: 18px;
}

.bg {
	position: absolute;
	right: -30px;
	width: 130px;
	top: 50%;
	transform: translateY(-50%);
}

.bg img {
	width: 100%;
	opacity: 0.25;
}

.skip {
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	color: var(--auth-primary);
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	max-width: fit-content;
	margin-inline: auto;
	border: none;
}
.skip:hover {
	background-color: transparent;
	box-shadow: none;
	border: none;
}

.skip:hover {
	color: var(--auth-primary-hover);
	cursor: pointer;
}

@media screen and (max-width: 991px) {
	.card {
		padding-left: 0;
	}
}

.errorBox {
	color: var(--error);
	padding: 10px;
	background-color: rgba(255, 0, 0, 0.13);
	border: 1px solid rgba(255, 0, 0, 0.35);
	border-radius: 20px;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 30px;
	text-align: center;
	animation: smallToBig 0.2s ease-in-out;
	height: 100%;
}

@keyframes smallToBig {
	0% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
}

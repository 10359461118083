.card {
  border: 1px solid rgba(255, 255, 255, 0.14);
  border-radius: 30px;
  background: linear-gradient(
    202deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  padding: 25px 29px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 40px;
  row-gap: 20px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 25px;
}

.avatar {
  border-radius: 50%;
  border: 1px solid #f6f6f6;
  width: 82px;
  height: 82px;
  position: relative;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.changeAvatar {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.changeAvatar svg {
  width: 14px;
  height: 14px;
  transition: all 0.3s ease;
}

.changeAvatar:hover {
  background-color: var(--auth-primary);
  cursor: pointer;
}

.changeAvatar:hover svg {
  color: var(--light);
}

.data {
  color: var(--light);
}

.name {
  margin-bottom: 7px;
  font-weight: 600;
  word-break: break-word;
  max-width: 700px;
}

.email {
  color: var(--light-text-main);
  font-size: 14px;
  font-weight: 500;
  word-break: break-all;
}
.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  justify-content: space-around;
}

.date {
  color: var(--light-text-main);
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  flex-grow: 2;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 160px;
  flex-shrink: 0;
  padding: 10px 15px;
  height: 34px;
  border-radius: 39px;
}

@media screen and (max-width: 575px) {
  .userInfo {
    flex-direction: column;
    text-align: center;
    margin-inline: auto;
  }
  .date {
    text-align: center;
    margin-top: 10px;
  }
  .grid {
    flex-direction: column;
  }
  .wrapper {
    align-items: center;
  }
}

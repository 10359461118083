.title {
	text-align: center;
	margin-bottom: 20px;
}

.background {
	position: relative;
	height: 135px;
	width: 135px;
	border-radius: 50%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 20px;
	margin-inline: auto;
	border: 1px solid #b9b9b9;
}

.label {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	height: 35px;
	width: 35px;
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	right: 0;
	transition: all 0.3s ease;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.13);
}

.label:hover {
	background-color: var(--auth-primary);
	color: var(--light);
}

.inputPic {
	display: none;
	opacity: 0;
}

.information {
	display: flex;
	align-items: center;
	column-gap: 15px;
}

.maxUpload {
	border-radius: 15px;
	background-color: #6792ff0a;
	border: 1px solid #1c139918;
	padding: 10px 15px;
	width: 50%;
	text-align: center;
	position: relative;
}

.maxUpload span {
	font-size: 14px;
	font-weight: 500;
}

.maxUpload .focus,
.maxUpload .focusNew {
	color: var(--auth-primary);
	font-weight: 700;
	margin-block: 5px 4px;
	font-size: 25px;
}
.focusNew {
	color: var(--auth-primary-hover);
}

.maxUpload svg {
	width: 30px;
	height: 30px;
	position: absolute;
	right: 0;
	top: -10px;
}

.allowed {
	font-size: 12px;
	margin-block: 5px 4px;
	text-align: center;
	margin-block: 12px 25px;
}

.buttonBox {
	width: 300px;
	margin-inline: auto;
}

.button {
	border-radius: 15px;
	width: 100%;
}

.header {
	text-align: center;
}
.header > h5 {
	margin-bottom: 20px;
}
.header > h3 {
	margin-bottom: 20px;
}

.header .kb {
	text-align: center;
	margin-top: 5px;
	font-size: 12px;
	margin-bottom: 20px;
}
.loaderWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
}
.loaderBackground {
	width: 350px;
	height: 10px;
	border-radius: 5px;
	background: #c9c9c9;
}
.loaderPipe {
	height: 10px;
	border-radius: 5px;
	background: var(--auth-primary);
}

@media screen and (max-width: 575px) {
	.maxUpload .focus,
	.maxUpload .focusNew {
		font-size: 20px;
	}
}

.card {
	width: 100%;
	padding-inline: 40px 10px;
	max-width: 600px;
	margin-inline: auto;
}

.title {
	margin-bottom: 15px;
}

.reminder {
	margin-bottom: 50px;
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	font-weight: 600;
}

.fillFormLabel {
	margin-bottom: 10px;
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	font-weight: 600;
	text-align: center;
}

.button {
	margin-bottom: 32px;
	width: 100%;
	border-radius: 50px;
}

.buttonFillForm{
	border-radius: 50px;
	width: 100%;
}
.skip {
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	color: var(--auth-primary);
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	max-width: fit-content;
	margin-inline: auto;
	border: none;
}
.skip:hover {
	background-color: transparent;
	box-shadow: none;
	border: none;
}

.skip:hover {
	color: var(--auth-primary-hover);
	cursor: pointer;
}

@media screen and (max-width: 991px) {
	.card {
		padding-left: 0;
	}
}

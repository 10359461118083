.terms h5 {
	display: inline-block;
	font-variant: small-caps;
}

.terms p {
	margin-block: 15px;
}

.termsLink{
	text-decoration: none;
	color: var(--auth-primary);
}
.card {
	width: 100%;
	padding-inline: 40px 10px;
	max-width: 600px;
	margin-inline: auto;
}

.title {
	margin-bottom: 15px;
}

.reminder {
	margin-bottom: 50px;
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	font-weight: 600;
}

.button {
	margin-bottom: 32px;
	width: 100%;
	border-radius: 50px;
}

.goBack {
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	color: var(--auth-primary);
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}

.goBack svg {
	font-size: 22px;
}

.goBack:hover {
	color: var(--auth-primary-hover);
	cursor: pointer;
}

.check {
	color: var(--auth-text-secondary);
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
	gap: 15px;
}

.resetted {
	text-align: center;
}

@media screen and (max-width: 991px) {
	.card {
		padding-left: 0;
	}
}

.center {
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 105px;
  position: relative;
  z-index: 10;
  font-weight: 500;
  flex-direction: column;
  color: var(--auth-text-primary: );
}

.bg {
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-position: top;
}

.flexContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-inline: 12px;
}

.center img {
  width: 100%;
  max-width: 280px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 20px;
}

.info {
  font-size: var(--font-size-lg);
  max-width: 600px;
}

.right {
  max-width: 446px;
}

.right image {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .flexContainer {
    flex-direction: column;
  }
  .title,
  .info {
    text-align: center;
  }
}

.logoutModal {
	text-align: center;
}

.title {
	margin-inline: auto;
	max-width: 330px;
	margin-bottom: 20px;
}

.info {
	text-align: center;
	padding-inline: 10px;
	max-width: 420px;
	color: var(--auth-text-secondary);
	margin-block: -10px 20px;
}

.logoutAll {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: center;
	color: var(--auth-text-secondary);
	margin-block: 20px;
}

.logoutButton {
	border-radius: 30px;
	width: 100%;
	margin-top: 10px;
	max-width: 300px;
}

.header {
	margin-block: 45px 31px;
	color: var(--light);
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	flex-wrap: wrap;
}

.referral {
	color: var(--light-text-main);
	border-radius: 46px;
	background: linear-gradient(
		202deg,
		rgba(255, 255, 255, 0.15) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	backdrop-filter: blur(14px);
	-webkit-backdrop-filter: blur(14px);
	padding: 10px 13px;
	font-size: 14px;
	border: 1px solid rgba(255, 255, 255, 0.14);
	display: flex;
	align-items: center;
	gap: 9px;
}
.referralText {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

@media screen and (max-width: 575px) {
	.header {
		flex-direction: column;
		row-gap: 15px;
	}
	.referralText {
		max-width: 230px;
	}
}

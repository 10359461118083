.doubleFactor {
	text-align: center;
}

.email {
	padding-bottom: 20px;
	border-bottom: 1px solid #ededed;
}

.doubleFactor img {
	margin-bottom: 20px;
}

.title {
	margin-bottom: 10px;
}

.info {
	font-size: 14px;
	color: #5e5e5e;
	padding-inline: 20px;
}

.form {
	margin-block: 35px 0;
	max-width: 400px;
	margin-inline: auto;
}

.form div:first-child {
	display: flex;
	column-gap: 10px;
	justify-content: center;
}

.form input {
	/* margin-bottom: 50px; */
	width: calc(100% / 6);
	height: 65px;
	outline: none;
	border-radius: 10px;
	text-align: center;
	border: 1px solid var(--input-border);
	font-size: 25px;
	filter: drop-shadow(0px 4px 7px rgba(222, 194, 255, 0.25));
}
.authCodeErrorMessage{
	margin-block: 10px 20px;
	font-size: 14px;
	color: var(--error);
	min-height: 17px;
}

.authenticator {
	padding-top: 20px;
}

.verify {
	width: 100%;
	border-radius: 55px;
	margin-bottom: 10px;
}

@media screen and (max-width: 575px) {
	.form input {
		height: 55px;
	}
}
@media screen and (max-width: 400px) {
	.form input {
		height: 45px;
	}
}

.errorBox {
	color: var(--error);
	padding: 10px;
	background-color: rgba(255, 0, 0, 0.13);
	border: 1px solid rgba(255, 0, 0, 0.35);
	border-radius: 20px;
	font-size: 12px;
	font-weight: 500;
	margin-block: 15px 0px;
	text-align: center;
	animation: smallToBig 0.2s ease-in-out;
	height: 100%;
}

@keyframes smallToBig {
	0% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
}

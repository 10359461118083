.card {
	width: 100%;
	padding-inline: 40px 10px;
	max-width: 600px;
	margin-inline: auto;
}

.title,
.titleSent {
	margin-bottom: 15px;
}

.titleSent {
	display: flex;
	align-items: center;
	gap: 10px;
}

.reminder {
	margin-bottom: 50px;
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	font-weight: 600;
}

.button {
	margin-bottom: 32px;
	width: 100%;
	border-radius: 50px;
}

.goBack {
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	color: var(--auth-primary);
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}

.goBack svg {
	font-size: 22px;
}

.goBack:hover {
	color: var(--auth-primary-hover);
	cursor: pointer;
}

.noReceive {
	font-weight: 600;
	text-align: center;
	color: var(--auth-text-secondary);
	text-decoration: underline;
}

.noReceive .resend {
	color: var(--auth-primary);
	text-decoration: underline;
}

.noReceive .resend:hover {
	color: var(--auth-primary-hover);
	cursor: pointer;
}

.sent {
	text-align: center;
}

.sent h4 {
	justify-content: center;
}

.waitResend,
.haveNotReceived {
	font-size: 14px;
	color: #5e5e5e;
}

.haveNotReceived {
	color: #5e5e5e;
}

.haveNotReceived > span {
	color: var(--auth-primary);
	cursor: pointer;
}

.captcha {
	max-width: 305px;
	margin-inline: auto;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
  }


@media screen and (max-width: 991px) {
	.card {
		padding-left: 0;
	}
}

.grid {
	margin-top: 22px;
	display: flex;
	flex-wrap: wrap;
	column-gap: 58px;
	row-gap: 54px;
	margin-bottom: 95px;
	animation: var(--fadeInAndScrollUp) 0.3s ease;
}

.titleSection {
	display: flex;
	align-items: center;
	column-gap: 19px;
	font-size: 600;
	padding-bottom: 30px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	margin-bottom: 30px;
}

.list {
	margin-block: 55px 0;
	animation: var(--fadeInAndScrollUp) 0.3s ease;
}

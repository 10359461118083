.card {
	background-color: hsl(200, 20%, 80%);
	width: 336px;
	border-radius: 18px;
	padding: 24px 11px 14px;
	border: 1px solid #323232;
	box-shadow: 10px 40px 64px 12px rgba(17, 54, 129, 0.06);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	animation: var(--fadeInAndScrollUp) 0.3s ease;
}

.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 67px;
	gap: 15px;
}

.platform {
	display: flex;
	align-items: center;
	gap: 10px;
	color: #eee;
	font-weight: 700;
}

.platform span {
	overflow: hidden;
	width: 180px;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

.favorite,
.like {
	height: 26px;
	width: 26px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.3);
	margin-right: 12px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.favorite svg,
.like svg {
	margin-top: 2px;
	animation: like 0.3s ease;
}

@keyframes like {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

.favorite svg {
	color: rgba(128, 128, 128, 0.74);
	font-size: 15px;
}

.like svg {
	font-size: 15px;
}

.like svg path {
	fill: #ff4343;
	stroke: #ff4343;
}

.users {
	display: flex;
	align-items: center;
	margin-bottom: 6px;
}

.circle {
	height: 26px;
	width: 26px;
	border-radius: 50%;
	flex-shrink: 0;
	border: 1px solid #fff;
	border-radius: 50%;
	position: relative;
	background-color: rgb(153, 153, 153);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.circle:nth-child(2),
.circle:nth-child(3) {
	margin-left: -6px;
	z-index: 2;
}

.circle:nth-child(3) {
	z-index: 3;
}

.footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.activeUser {
	font-size: 14px;
	color: #fff;
	font-weight: 500;
}

.button {
	border-radius: 25px;
	background: rgba(255, 255, 255, 0.07);
	backdrop-filter: blur(15.5px);
	padding: 10px 25px;
	font-size: 10px;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
	height: 32px !important;
	transition: all 0.3s ease;
	overflow: hidden;
}

.loginPopup {
	margin-inline: auto;
	text-align: center;
	max-width: 350px;
	margin-bottom: 20px;
}

.loginPopup h5 {
	margin-bottom: 10px;
}

.loginPopup p {
	margin-bottom: 50px;
	color: var(--auth-text-secondary);
}
.loginPopup a {
	background-color: var(--auth-primary);
	padding: 10px 30px;
	border-radius: 20px;
	color: var(--light);
	text-decoration: none;
}
.loginPopup a:hover {
	background-color: var(--auth-primary-hover);
}

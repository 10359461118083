.sso {
	background-color: rgba(255, 255, 255, 0.8);
	padding: 25px 20px;
	border-radius: 20px;
	backdrop-filter: blur(10.5px);
	-webkit-backdrop-filter: blur(10.5px);
	width: 100%;
	max-width: 450px;
	transition: all 0.3s ease;
}
.sso p {
	margin-top: 10px;
}

.title {
	margin-bottom: 10px;
}

.loader {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 400px;
}

.logoPlatform {
	width: 30px;
	flex-shrink: 0;
}

.loader > div > div:after {
	top: 40px;
	left: 30px;
	width: 20px;
	height: 8px;
}

.buttons {
	margin-top: 40px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.exit {
	min-width: 120px;
	border-radius: 30px;
	background-color: white;
	color: var(--auth-primary);
}

.exit:hover {
	background-color: rgba(255, 255, 255, 0.8);
}

.proceed {
	flex-grow: 2;
	border-radius: 30px;
}

.platform {
	margin-block: 30px 40px;
	font-size: 20px;
	font-weight: 500;
	display: flex;
	align-items: center;
	gap: 8px;
}

.moreStepsModal {
	text-align: center;
	padding: 20px 0;
}
.moreStepsModal > * {
	margin-bottom: 20px;
}

.moreStepBtn {
	border-radius: 50px;
	margin-bottom: 0;
}

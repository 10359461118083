.texts {
	color: var(--auth-text-secondary);
	text-align: left;
}
.grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 22px;
	margin-block: 14px 30px;
}

.item {
	background-color: #ebecee;
	border-radius: 16px;
	height: 6px;
}

.one {
	background-color: var(--error);
}

.two,
.three {
	background-color: var(--warning);
}

.four {
	background-color: var(--success);
}

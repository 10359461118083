.card {
	width: 100%;
	padding-inline: 40px 10px;
	max-width: 600px;
	margin-inline: auto;
}

.title {
	margin-bottom: 20px;
}

.content {
	max-height: 456px;
	overflow-y: auto;
	overflow-x: hidden;
	/* margin-bottom: 50px; */
	padding-right: 20px;
}

.content::before {
	content: '';
	display: block;
	height: 20px;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(
		180deg,
		rgb(255, 255, 255) 0%,
		rgba(255, 255, 255, 0.562) 100%
	);
}

.content::after {
	content: '';
	display: block;
	height: 20px;
	position: sticky;
	bottom: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(
		0deg,
		rgb(255, 255, 255) 0%,
		rgba(255, 255, 255, 0.562) 100%
	);
}

.accept {
	margin-bottom: 32px;
	display: flex;
	align-items: center;
	column-gap: 8px;
	color: var(--auth-text-secondary);
	font-weight: 500;
}

.accept label {
	transition: all 0.3s ease;
}

.accept label:hover {
	cursor: pointer;
	color: var(--auth-primary-hover);
}

.button {
	width: 100%;
	border-radius: 50px;
}

@media screen and (max-width: 991px) {
	.card {
		padding-left: 0;
	}
}

.listBox,
.listBoxClosed {
  background-color: rgba(255, 255, 255, 0.397);
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
  height: 100dvh;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 900px;
  z-index: 200;
  box-shadow: inset 1000px 0px 10px -10px rgba(0, 2, 10, 0.6);
  border-left: 1px solid rgba(70, 70, 70, 1);
  transform: translateX(100%);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.listBox {
  transform: translateX(0%);
}

.header {
  min-height: calc(var(--header-main) + 1px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  color: var(--light);
}

.loadMore,
.reload {
  background-color: rgba(255, 255, 255, 0.02);
  box-shadow: none;
  border: none;
  min-height: 50px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.loadMore svg,
.reload svg {
  font-size: 16px;
}

.loadMore:hover,
.reload:hover {
  min-height: 50px;
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  box-shadow: none;
  filter: brightness(2);
}

.menu {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 10px;
  align-items: center;
  color: var(--light);
  font-weight: 400;
  padding: 10px 30px;
}

.menu > div {
  flex-grow: 2;
  color: #a1a1a1;
  transition: all 0.3s ease;
}
.menu > div:hover {
  color: var(--light);
  cursor: pointer;
}

.selected {
  color: var(--auth-primary) !important;
}

.title {
  font-weight: 500;
}

.header small {
  color: #a1a1a1;
}

.header small:hover {
  color: var(--auth-primary);
  cursor: pointer;
}

.close {
  height: 40px;
  width: 40px;
  border-radius: 15px;
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.3s ease;
}

.close:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}

.list {
  overflow-y: auto;
  padding: 30px;
  overflow-x: hidden;
  flex-grow: 2;
}

.item {
  display: flex;
  column-gap: 25px;
  /* transition: all 2s ease; */
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.type {
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}

.type::before {
  content: "";
  display: block;
  height: 100%;
  width: 0px;
  border-left: dashed 2px #dbdfe963;
  padding: 20px;
  display: inline-block;
  font-size: 40px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 0.5px);
  z-index: -1;
}

.text {
  color: var(--light-text-secondary);
  padding-bottom: 15px;
  padding-right: 60px;
  padding-top: 4px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #a1a1a1;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #a0a0a038;
  color: #9ba3b8;
}

.time {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-text-ternary);
  margin-left: auto;
  padding-bottom: 50px;
  height: auto;
}

.item:last-child {
  margin-bottom: 0;
}

.item:last-child .type::before {
  display: none;
}

.item:last-child .text {
  padding-bottom: 0;
}

.delete {
  font-size: 10px;
  margin-top: 5px;
  color: #a1a1a1;
}
.delete:hover {
  color: var(--auth-primary);
  cursor: pointer;
}

.deleting {
  transition: all 0.15s;
  visibility: hidden;
  opacity: 0;
  transform: translateX(100%);
}

.buttons {
  display: flex;
  align-items: center;
}

.reload {
  width: 150px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.reload:hover {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.loadMore {
  flex-grow: 2;
}

@media screen and (max-width: 575px) {
  .text {
    padding-right: 10px;
  }
}

.authentication {
  height: 100dvh;
  overflow: hidden;
}

.header {
  height: var(--header-auth);
  display: grid;
  grid-template-columns: 1fr 1fr;
  transition: all 0.5s ease;
  max-width: 1550px;
  margin-inline: auto;
  padding: 0 15px;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo img {
  transition: all 0.5s ease;
  width: 230px;
}

.langNavigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reminder {
  padding-left: 20px;
  color: var(--auth-text-secondary);
  transition: all 0.5s ease;
}

.reminder .cta {
  color: var(--auth-primary);
  font-weight: 600;
}

.reminder .cta:hover {
  color: var(--auth-primary-hover);
  cursor: pointer;
}

.main {
  height: calc(100% - var(--header-auth) - var(--footer-auth));
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  max-width: 1550px;
  margin-inline: auto;
  padding: 0 15px;
  transition: all 0.5s ease;
}

.render {
  height: 100%;
  padding: 25px 10px;
  overflow-y: scroll;
  overflow: overlay;
  transition: all 0.5s ease;
}

.centerContent {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.slider {
  height: 100%;
  padding: 10px;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
}

.supportBtn {
  border-radius: 30px 8px 30px 30px;
  background-color: var(--auth-primary);
  z-index: 2147483647;
  cursor: pointer;
  margin-top: 10px;
  padding: 10px 25px;
  transition: all 0.3s ease-out 0s;
  color: var(--light);
  text-decoration: none;
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 1.125rem;
  max-width: 150px;
}

.supportBtn:hover {
  background-color: var(--auth-primary-hover);
}

.footer {
  height: var(--footer-auth);
  margin-inline: auto;
  padding: 10px 15px;
  max-width: 1550px;
}
.footer > a {
  margin-left: auto;
}

@media screen and (max-width: 991px) {
  .main {
    grid-template-columns: 1fr;
    overflow-y: auto;
  }
  .render {
    overflow-y: unset;
    overflow: unset;
  }
  .slider {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .logo img {
    width: 200px;
  }
  .resp {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .logo img {
    width: 170px;
  }
}

.develop {
  position: fixed;
  top: 60px;
  left: 50px;
  cursor: pointer;
}

.bg {
  width: 320px;
  line-height: 1.3;
  position: absolute;
  top: 110%;
  background-color: blanchedalmond;
  padding: 20px;
}

.errorBox {
	color: var(--error);
	padding: 10px;
	background-color: rgba(255, 0, 0, 0.13);
	border: 1px solid rgba(255, 0, 0, 0.35);
	border-radius: 20px;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 30px;
	text-align: center;
	animation: smallToBig 0.2s ease-in-out;
	height: 100%;
}

@keyframes smallToBig {
	0% {
		/* height: 0; */
		transform: scale(0.5);
	}
	100% {
		/* height: auto; */
		transform: scale(1);
	}
}

.header {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	background: rgba(255, 255, 255, 0.01);
	backdrop-filter: blur(24px);
	-webkit-backdrop-filter: blur(24px);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
}

.navbar {
	height: var(--header-main);
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 20px;
}
/* Left */
.menu {
	display: flex;
	align-items: center;
	flex-grow: 2;
}

.logo {
	display: flex;
	align-items: center;
	gap: 27px;
}

.logo img {
	width: 180px;
	cursor: pointer;
}

.pipe {
	height: 34px;
	width: 1px;
	background-color: rgba(255, 255, 255, 0.42);
	margin-right: 40px;
}

.responsive {
	display: flex;
	align-items: center;
	flex-grow: 2;
	container: navigator / inline-size;
}

.nav ul {
	list-style: none;
	display: flex;
	align-items: center;
	column-gap: 40px;
}

.nav ul li {
	font-size: 16px;
	font-weight: 500;
	color: #d4d4d4;
	cursor: pointer;
}
.nav ul li.active {
	color: #fff;
	position: relative;
}

.active::after {
	position: absolute;
	content: '';
	display: block;
	height: 6px;
	width: 6px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 50%;
	background-color: var(--active);
	margin-top: 6px;
}

.queryNav {
	margin-left: 40px;
	position: relative;
	display: none;
}

.toOpen,
.opened {
	display: flex;
	align-items: center;
	color: #d4d4d4;
	transition: all 0.3s ease;
	display: none;
}

.toOpen:hover,
.opened:hover {
	color: #fff;
	cursor: pointer;
}

.opened {
	transform: rotate(180deg);
}

.dropdown,
.notDropdown {
	position: absolute;
	z-index: 3;
	top: 63px;
	left: calc(280px / 2 * -1);
	width: 280px;
	background-color: rgb(14, 18, 24);
	color: #d4d4d4;
	padding: 23px 19px 21px;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(0, 0, 0, 0.14);
	transition: all 0.3s ease;
	overflow: hidden;
}
.dropdown {
	animation: var(--fadeInAndScrollUp) 0.5s;
}

.notDropdown {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

.dropdown ul,
.notDropdown ul {
	display: flex;
	flex-direction: column;
	row-gap: 23px;
	list-style: none;
	font-size: 16px;
	font-weight: 500;
}

.dropdown ul li,
.notDropdown ul li {
	display: none;
}

.dropdown ul li:hover,
.notDropdown ul li:hover {
	color: #fff;
	cursor: pointer;
}

.responsiveList li.active {
	color: #fff;
	position: relative;
	transition: all 0.3s ease;
}

.responsiveList .active::before {
	position: absolute;
	transition: all 0.3s ease;
	content: '';
	display: block;
	height: 2px;
	width: 120px;
	left: 0;
	bottom: -5px;
	background-color: var(--active);
	border-radius: 0;
	margin-top: 0;
	animation: widthanimation 0.3s ease;
}
.responsiveList .active::after {
	display: none;
}

@keyframes widthanimation {
	0% {
		width: 0;
	}
	100% {
		width: 120px;
	}
}

/* Right */
.actions {
	display: flex;
	align-items: center;
	gap: 26px;
}

@container navigator (max-width: 1000px) {
	.nav
		ul
		:is(li:nth-last-child(1), li:nth-last-child(2), li:nth-last-child(3)) {
		display: none;
	}
	.queryNav,
	.toOpen,
	.opened,
	.notDropdown
		ul
		:is(li:nth-last-child(1), li:nth-last-child(2), li:nth-last-child(3)),
	.dropdown
		ul
		:is(li:nth-last-child(1), li:nth-last-child(2), li:nth-last-child(3)) {
		display: block;
	}
}

@container navigator (max-width: 670px) {
	.nav
		ul
		:is(li:nth-last-child(4), li:nth-last-child(5), li:nth-last-child(6)) {
		display: none;
	}
	.notDropdown
		ul
		:is(li:nth-last-child(4), li:nth-last-child(5), li:nth-last-child(6)),
	.dropdown
		ul
		:is(li:nth-last-child(4), li:nth-last-child(5), li:nth-last-child(6)) {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	.responsive,
	.pipe {
		display: none;
	}
}

@media screen and (max-width: 400px) {
	.logo img {
		width: 141px;
		cursor: pointer;
	}
}

.notification {
	margin-block: 45px 55px;
	margin-bottom: 30px;
	animation: var(--fadeInAndScrollUp) 0.5s ease;
}
.subtitle {
	font-size: 15px;
	color: var(--auth-text-secondary);
	margin-bottom: 30px;
	font-weight: 500;
}

.lineTitle {
	margin-bottom: 6px;
}

.notificationSetting {
	padding: 26px 30px 31px 30px;
	border-radius: 8px;
	border: 1px solid #ededed;
}

.notificationSetting h5 {
	font-weight: 600;
	margin-bottom: 15px;
}

.line {
	display: grid;
	grid-template-columns: 1fr minmax(400px, 400px);
	padding: 28px 0;
	align-items: center;
}

.line:not(:last-child) {
	border-bottom: 1px solid var(--input-border);
}

.line:last-child {
	padding-bottom: 0;
}

.label {
	font-weight: 500;
}

.selection {
	display: grid;
	grid-template-columns: 1fr 1fr;
	place-items: end;
	font-weight: 500;
	gap: 10px;
}

.check {
	display: flex;
	align-items: center;
	gap: 15px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
}

@media screen and (max-width: 1199px) {
	.line {
		grid-template-columns: 1fr minmax(300px, 300px);
	}
}

@media screen and (max-width: 767px) {
	.line {
		grid-template-columns: 1fr;
	}
	.selection {
		display: flex;
		justify-content: flex-end;
		gap: 30px;
	}
	.label {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 575px) {
	.label {
		text-align: center;
		margin-bottom: 20px;
	}
	.line {
		padding: 20px 0;
	}
	.selection {
		justify-content: center;
	}
}

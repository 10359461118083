.card {
	width: 100%;
	max-width: 350px;
	margin-inline: auto;
}

.title {
	padding-top: 10px;
	margin-bottom: 15px;
	text-align: center;
}
.smallText{
	text-align: center;
	font-size: 14px;
	color: var(--auth-text-secondary);
	margin-bottom: 20px;
}

.reminder {
	margin-bottom: 50px;
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	font-weight: 600;
	text-align: center;
}

.reminder .cta {
	color: var(--auth-primary);
}

.reminder .cta:hover {
	color: var(--auth-primary-hover);
	cursor: pointer;
}

.remember {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	column-gap: 8px;
	color: var(--auth-text-secondary);
}

.remember label {
	transition: all 0.3s ease;
}

.remember label:hover {
	cursor: pointer;
	color: var(--auth-primary-hover);
}

.button {
	margin-bottom: 10px;
	width: 100%;
	border-radius: 50px;
}

.forgot {
	color: var(--auth-text-secondary);
	text-align: center;
	transition: all 0.5s ease;
	text-decoration: underline;
	font-weight: 600;
}

.forgot:hover {
	color: var(--auth-primary-hover);
	cursor: pointer;
}

.captcha {
	max-width: 305px;
	margin-inline: auto;
	margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
	.card {
		padding-left: 0;
	}
}

.errorBox {
	color: var(--error);
	padding: 10px;
	background-color: rgba(255, 0, 0, 0.13);
	border: 1px solid rgba(255, 0, 0, 0.35);
	border-radius: 20px;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 30px;
	text-align: center;
	animation: smallToBig 0.2s ease-in-out;
	height: 100%;
}

@keyframes smallToBig {
	0% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
}

.title {
	margin-bottom: 15px;
}

.button {
	width: 100%;
	border-radius: 50px;
	margin-bottom: 10px;
}

.form {
	max-width: 458px;
	margin-inline: auto;
}

.form div:first-child {
	display: flex;
	column-gap: 10px;
	justify-content: center;
}

.form input {
	margin-bottom: 50px;
	width: calc(100% / 6);
	height: 65px;
	outline: none;
	border-radius: 10px;
	text-align: center;
	border: 1px solid var(--input-border);
	font-size: 25px;
	filter: drop-shadow(0px 4px 7px rgba(222, 194, 255, 0.25));
}

.expired {
	text-align: center;
}

.expired h5 {
	margin-block: 10px;
}

.expired p {
	font-size: 14px;
	color: #5e5e5e;
	margin-bottom: 20px;
}

.buttonLogin {
	width: 100%;
	max-width: 300px;
	border-radius: 55px;
	margin-block: 10px;
}

@media screen and (max-width: 575px) {
	.form input {
		height: 55px;
	}
}
@media screen and (max-width: 400px) {
	.form input {
		height: 45px;
	}
}

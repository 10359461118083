.card {
	border: 1px solid rgba(255, 255, 255, 0.14);
	border-radius: 30px;
	background: linear-gradient(
		202deg,
		rgba(255, 255, 255, 0.15) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	backdrop-filter: blur(14px);
	-webkit-backdrop-filter: blur(14px);
	padding: 32px 32px 16px;
}

.title {
	color: var(--light);
	margin-bottom: 21px;
}
.list {
	overflow-y: auto;
	max-height: 205px;
	scrollbar-color: rgba(255, 255, 255, 0.38) rgba(255, 255, 255, 0.05); /* Only Firefox */
	scrollbar-width: 7px;
}

.list::-webkit-scrollbar {
	width: 7px; /* Chrome */
	background: rgba(255, 255, 255, 0.38) rgba(255, 255, 255, 0.05); /* Chrome */
}

.list::-webkit-scrollbar-track {
	background-clip: content-box;
	background-color: rgba(255, 255, 255, 0.05);
	width: 10px;
	border-radius: 24px;
}

.list::-webkit-scrollbar-thumb {
	border: 1px solid transparent;
	background-color: rgba(255, 255, 255, 0.38);
	border-radius: 24px;
}

.list::-webkit-scrollbar-corner {
	background-color: transparent;
}

.item {
	display: flex;
	column-gap: 25px;
	margin-bottom: 10px;
}

.type {
	overflow: hidden;
	flex-shrink: 0;
}

.type::after {
	content: '';
	display: block;
	height: 100%;
	width: 0px;
	border: 1px solid var(--light);
	border-image: url('../../../../../assets/images/dashed.png') 1 stretch;
	border-image-width: 1;
	margin-left: 11.5px;
	margin-top: 10px;
}

.text {
	font-size: 15px;
	color: var(--light-text-secondary);
	padding-bottom: 15px;
	padding-right: 60px;
	padding-top: 4px;
}

.icon {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.time {
	margin-top: 4px;
	font-size: 12px;
	font-weight: 500;
	color: var(--light-text-ternary);
	margin-left: auto;
}

.item:last-child {
	margin-bottom: 0;
}

.item:last-child .type::after {
	display: none;
}

.item:last-child .text {
	padding-bottom: 0;
}

.empty {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 20px 0;
	color: #a1a1a1;
}

.iconBox {
	height: 36px;
	width: 36px;
	border-radius: 50%;
	backdrop-filter: blur(10.5px);
	-webkit-backdrop-filter: blur(10.5px);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	transition: all 0.3s ease;
}

.iconBox:hover {
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.05);
}

.new {
	position: absolute;
	top: 1px;
	right: 2px;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	background-color: #ff7a00;
	animation: pulse 10s infinite ease;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #ff7a00;
	}
	10% {
		transform: scale(1);
		box-shadow: 0 0 0 7px rgba(255, 255, 255, 0);
	}
	25% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@media screen and (max-width: 767px) {
	.notification {
		display: none;
	}
}

.card {
  width: 100%;
  padding-inline: 10px;
  max-width: 600px;
  margin-inline: auto;
}

.title {
  margin-bottom: 15px;
}

.reminder {
  margin-bottom: 50px;
  color: var(--auth-text-secondary);
  transition: all 0.5s ease;
  font-weight: 600;
}

.reminder .cta {
  color: var(--auth-primary);
}

.reminder .cta:hover {
  color: var(--auth-primary-hover);
  cursor: pointer;
}

.remember {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: var(--auth-text-secondary);
}

.remember label {
  transition: all 0.3s ease;
}

.remember label:hover {
  cursor: pointer;
  color: var(--auth-primary-hover);
}

.button {
  margin-bottom: 32px;
  width: 100%;
  border-radius: 50px;
}

.forgot {
  color: var(--auth-text-secondary);
  text-align: center;
  transition: all 0.5s ease;
  text-decoration: underline;
  font-weight: 600;
}

.forgot:hover {
  color: var(--auth-primary-hover);
  cursor: pointer;
}

.captcha {
  max-width: 305px;
  margin-inline: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .card {
    padding-left: 0;
  }
}

.errorBox {
  color: var(--error);
  padding: 10px;
  background-color: rgba(255, 0, 0, 0.13);
  border: 1px solid rgba(255, 0, 0, 0.35);
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
  animation: smallToBig 0.2s ease-in-out;
  height: 100%;
}

@keyframes smallToBig {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

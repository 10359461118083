.personal {
	margin-bottom: 30px;
	animation: var(--fadeInAndScrollUp) 0.5s ease;
}

.data,
.residential {
	padding: 20px 5px 0;
	border-radius: 8px;
}

.data {
	margin-bottom: 30px;
}

.data h5,
.residential h5 {
	font-weight: 600;
	margin-bottom: 31px;
	text-align: center;
}

.editable {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.edit {
	margin-bottom: 31px;
	border-radius: 6px;
	border: 1px solid rgba(129, 136, 149, 0.25);
	padding: 8px 14px;
	display: flex;
	align-items: center;
	gap: 4px;
	color: #818895;
	font-weight: 600;
	font-size: 14px;
	transition: all 0.3s ease;
}

.edit:hover {
	border: 1px solid var(--auth-primary);
	background-color: var(--auth-primary);
	color: #fff;
	cursor: pointer;
}

.grid,
.gridAddress {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 40px;
}

.gridAddress {
	row-gap: 5px;
}

.label {
	font-weight: 400;
	font-size: 14px;
	color: #242625;
	margin-bottom: 12px;
}

.values {
	font-weight: 500;
}

.gridAddress label,
.grid label {
	font-weight: 400;
	font-size: 14px;
	color: #242625;
	margin-bottom: 12px;
}

.inputEditable {
	border: none;
	padding: 0 !important;
}

.inputEditable input {
	font-weight: 500;
	color: black;
	font-size: var(--font-size-base);
}

.buttons {
	margin-left: auto;
	width: 183px;
}

.save {
	width: 100%;
	padding: 16px;
	border-radius: 39px;
}

.option {
	display: flex;
	align-items: center;
	font-size: 13px;
}

.flag {
	font-size: 20px;
	margin-right: 10px;
	display: flex;
	align-items: center;
}

.selectError {
	border: 1px solid red;
	min-height: 51px;
	padding: 11px 15px;
	background: #fff;
	color: var(--auth-text-primary);
	border-radius: 20px;
	min-width: 80px;
	transition: all 0.5s ease;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.errorMessageClassName {
	padding: 1px 5px 0;
	width: 100%;
	line-height: 15px;
	font-size: 13px;
	margin: 8px 0 9px;
	font-size: 12px;
	text-align: right;
	color: var(--error);
	min-height: 15px;
}

.para {
	margin-block: 30px;
	font-size: 15px;
	color: var(--auth-text-secondary);
}

.button {
	border-radius: 10px;
	width: 100%;
}

@media screen and (max-width: 767px) {
	.grid,
	.gridAddress {
		grid-template-columns: 1fr;
	}
}

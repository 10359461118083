.center {
	height: 100dvh;
	width: 100%;
	background-image: url('../../assets/images/hero-bg.webp');
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: cover;
	background-position: top left;
	background-repeat: no-repeat;
	font-size: 105px;
	font-weight: 600;
	flex-direction: column;
	color: var(--light);
}

.info {
	font-size: var(--font-size-md);
}

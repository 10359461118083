.container {
  /* animation: var(--fadeIn); */
  max-width: 1550px;
  padding-inline: 15px;
}

/* Button */
.buttonStyle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: var(--light);
  background-color: var(--auth-primary);
  transition: all 0.3s ease;
  user-select: none;
  height: 50px;
  padding: 16px;
}

.buttonStyle:hover {
  background-color: var(--auth-primary-hover);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.26);
}

.buttonStyle:active {
  scale: 1.05;
}

.buttonStyleDisabled {
  border: 1px solid transparent;
  opacity: 0.5;
}

.buttonStyleDisabled:hover {
  box-shadow: none;
}

.buttonStyleDisabled:active {
  scale: 1;
}

/*  InputField  */
.inputFieldStyle,
.inputFieldErrorStyle,
.inputFieldSuccessStyle {
  padding: 15px 28px;
  flex-direction: row-reverse;
  color: #697180;
  align-items: center;
  gap: 5px;
  border: 1.2px solid var(--input-border);
  border-radius: 50px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
  min-width: 100px;
}

.inputFieldErrorStyle {
  border: 1.2px solid var(--error);
  color: var(--error);
}

.inputFieldStyle > input,
.inputFieldErrorStyle > input,
.inputFieldSuccessStyle > input,
.inputFieldStyle > textarea,
.inputFieldErrorStyle > textarea,
.inputFieldSuccessStyle > textarea {
  padding: 0;
  color: var(--auth-text-primary);
  caret-color: var(--auth-text-primary);
  transition: all 0.3s;
}

.inputFieldLabelStyle {
  display: block;
  color: var(--auth-text-primary);
  font-weight: 600;
  margin-bottom: 12px;
  text-align: start;
  white-space: break-spaces;
}

.inputFieldErrorMessageStyle {
  margin: 8px 0 9px;
  font-size: 12px;
  text-align: right;
  color: var(--error);
  min-height: 15px;
}

/*  Select  */
.selectStyle {
  min-height: 51px;
  padding: 11px 15px;
  background: #fff;
  border: 1px solid var(--input-border);
  color: var(--auth-text-primary);
  border-radius: 30px;
  min-width: 80px;
  transition: all 0.5s ease;
}

.selectStyle span {
  transition: all 0.5s ease;
  margin-right: 5px;
}

.selectPlaceholderClass {
  color: var(--light-text-secondary);
  transition: all 0.5s ease;
}

.selectOpenedClass {
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border);
  background-color: #fff;
  width: calc(100% + 1.9px);
  left: -0.95px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  scrollbar-color: var(--auth-primary) transparent; /* Only Firefox */
}

.selectOpenedClass::-webkit-scrollbar {
  width: 4px; /* Chrome */
  background: var(--auth-primary) transparent; /* Chrome */
}

.selectOptionClass {
  padding: 15px;
  color: var(--auth-text-primary);
  transition: all 0.5s ease;
}

.selectOptionClass:hover {
  background-color: rgba(128, 128, 128, 0.226);
}

/* Modal */
.magicModal {
  border-radius: 10px;
  max-width: 450px;
  background-color: white;
  animation: modalappear 0.3s ease;
}

@keyframes modalappear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ------------ Otp Component ------------- */
.otpContainer {
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 0 20px;
}
.otpInputStyle,
.otpErrorStyle {
  height: 65px;
  /* width: 100% !important;100% !important; */
  width: 65px !important;
  font-size: 24px;
  background: transparent;
  border-radius: 8px;
  caret-color: var(--auth-text-primary);
  color: var(--auth-text-primary);
}

.otpInputStyle {
  border: 1px solid var(--border);
}
.otpErrorStyle {
  border: 1px solid rgba(190, 17, 17, 0.4);
}
.otpFocusStyle {
  outline: none;
  border: 2px solid var(--auth-primary-hover);
}

.otpDisabledStyle {
  opacity: 0.3;
  user-select: none;
  cursor: not-allowed;
}

@media screen and (max-width: 1199px) and (min-width: 991px) {
  .otpInputStyle {
    height: 2.5em;
  }
}

@media screen and (max-width: 575px) {
  .otpInputStyle {
    height: 2.5em;
    width: 55px !important;
  }
}
@media screen and (max-width: 480px) {
  .otpInputStyle {
    height: 1.85em;
    width: 100% !important;
  }
}
/* ------------ Otp Component ------------- */

.card {
  border: 1px solid rgba(255, 255, 255, 0.14);
  border-radius: 30px;
  background: linear-gradient(
    202deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  padding: 32px;
}

.title {
  color: var(--light);
  margin-bottom: 32px;
}

.grid {
  display: grid;
  grid-template-columns: 340px 2fr;
  column-gap: 30px;
}

.profileInfo {
  display: grid;
  grid-template-columns: 70px 1fr;
  column-gap: 25px;
  align-items: center;
  color: var(--light);
  margin-bottom: 40px;
}

.picture {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--light);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.name {
  font-weight: 600;
  word-break: break-all;
}

.email {
  font-size: 14px;
  margin-top: 2px;
  word-break: break-all;
}

.progress {
  max-width: 250px;
}

.completation {
  color: var(--light);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.completation svg {
  font-size: 20px;
  transition: all 0.3s ease;
}

.completation svg:hover {
  color: var(--warning-dark);
  cursor: pointer;
}

.bar {
  width: 100%;
  height: 5px;
  background: rgba(230, 224, 233, 0.4);
  border-radius: 13px;
  position: relative;
  margin-bottom: 12px;
}

.progression {
  position: absolute;
  height: 100%;
  border-radius: 13px;
}

.percentage {
  font-size: 14px;
  color: #eee;
}

.gridTabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 40px;
}

.element {
  display: flex;
  column-gap: 28px;
}

.element img {
  margin-top: 10px;
}

.data {
  color: var(--light);
}

.label {
  font-size: 14px;
  margin-top: 4px;
}

.days {
  display: flex;
  align-items: flex-end;
  gap: 3px;
}

.days span {
  font-size: 11px;
  margin-bottom: 6px;
}

.infoBadge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  position: relative;
}

.infoBadge svg {
  font-size: 18px;
  color: var(--light);
  transition: all 0.3s ease;
  cursor: pointer;
}

.infoProfileActive,
.infoProfile {
  position: absolute;
  bottom: 0;
  left: -13px;
  width: 250px;
  color: black;
  font-size: 14px;
  padding-bottom: 30px;
  z-index: 2;
  transition: all 0.3s ease;
}

.infoProfileActive {
  animation: var(--fadeInAndScrollUp) 0.5s;
}

.infoProfile {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.content {
  background-color: #fff;
  font-weight: 500;
  border-radius: 8px;
  padding: 20px 15px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 991px) {
  .grid {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
  .progress {
    max-width: 100%;
  }
  .infoProfileActive,
  .infoProfile {
    padding-bottom: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 575px) {
  .gridTabs {
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 40px;
  }
  .name {
    font-size: 14px;
  }
  .email {
    font-size: 12px;
  }
}

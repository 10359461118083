@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

:root {
	/* Colors */
	--light: #ffffff;

	--auth-primary: #6792ff;
	--auth-primary-hover: #4f7dff;
	--auth-text-primary: #1a1f2b;
	--auth-text-secondary: #707787;

	--light-text-main: #e6e6e6;
	--light-text-secondary: #ddd;
	--light-text-tertiary: #dcdcdc;

	--error: #de4141;
	--warning: #ffd600;
	--warning-dark: #de9731;
	--success: #7bde6a;
	--active: #7f4fee;

	--border: #5a5a5a;

	/* Input */
	--input-border: #e7e7e7;
	--input-placeholder: #cdcdcd;

	/* Layouts */
	--header-auth: 80px;
	--footer-auth: 80px;
	--header-main: 90px;

	/* Animation */
	--fadeIn: fadeIn 0.5s ease;
	--fadeInAndScrollUp: fadeInAndScrollUp;
	--appearScale: animationAppear 0.5s ease;
}

::-webkit-scrollbar {
	width: 2px;
	height: 2px;
}

::-webkit-scrollbar-track {
	background-clip: content-box;
	background-color: #126ab327;
	width: 20px;
	border-radius: 24px;
}

::-webkit-scrollbar-thumb {
	border: 1px solid transparent;
	background-color: #849aac;
	border-radius: 24px;
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scrollbar-color: #849aac #849aac27;
}

html {
	scroll-behavior: smooth;
	scrollbar-width: thin;
}

body {
	scrollbar-width: thin;
	scroll-behavior: smooth;
	font-size: var(--font-size-base);
	font-family: 'Inter', sans-serif;
	/* todo: test mobile safe area  content="viewport-fit=cover"  */
	/* padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
		env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px); */
}

h1 {
	font-size: var(--font-size-xxxl);
}

h2 {
	font-size: var(--font-size-xxl);
}

h3 {
	font-size: var(--font-size-xl);
}

h4 {
	font-size: var(--font-size-lg);
}

h5 {
	font-size: var(--font-size-md);
}

input::placeholder {
	color: var(--input-placeholder);
}

input[type='checkbox'] {
	accent-color: #4f7dff;
	width: 16px;
	height: 16px;
	cursor: pointer;
}

/* Fluid font size variables, for browsers that support clamp */
@supports (font-size: clamp(1rem, 1vw, 1rem)) {
	:root {
		--font-size-sm: clamp(0.7rem, 0.1vw + 0.68rem, 0.8rem);
		--font-size-base: clamp(0.88rem, 0.19vw + 0.84rem, 1.06rem);
		--font-size-md: clamp(1.09rem, 0.32vw + 1.03rem, 1.42rem);
		--font-size-lg: clamp(1.37rem, 0.52vw + 1.26rem, 1.89rem);
		--font-size-xl: clamp(1.71rem, 0.81vw + 1.55rem, 2.52rem);
		--font-size-xxl: clamp(2.14rem, 1.22vw + 1.89rem, 3.35rem);
		--font-size-xxxl: clamp(2.67rem, 1.8vw + 2.31rem, 4.47rem);
	}
}

/* Fallback variables for browsers that don't support clamp */
@supports not (font-size: clamp(1rem, 1vw, 1rem)) {
	:root {
		--font-size-sm: 0.7rem;
		--font-size-base: 0.88rem;
		--font-size-md: 1.09rem;
		--font-size-lg: 1.37rem;
		--font-size-xl: 1.71rem;
		--font-size-xxl: 2.14rem;
		--font-size-xxxl: 2.67rem;
	}
	@media screen and (min-width: 1920px) {
		:root {
			--font-size-sm: 0.8rem;
			--font-size-base: 1.06rem;
			--font-size-md: 1.42rem;
			--font-size-lg: 1.89rem;
			--font-size-xl: 2.52rem;
			--font-size-xxl: 3.35rem;
			--font-size-xxxl: 4.47rem;
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeInAndScrollUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes animationAppear {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

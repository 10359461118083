.card {
  width: 100%;
  padding-inline: 10px;
  max-width: 600px;
  margin-inline: auto;
}
.section {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}
.title {
  margin-bottom: 10px;
}
.steps {
  margin: auto;
  max-width: 400px;
  list-style-type: decimal;
  padding-left: 10px;
}
.desc,
.step {
  color: var(--auth-text-secondary);
  margin-bottom: 10px;
}
.step {
  text-align: start;
}
.otpErrorMessage {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 14px;
  color: var(--error);
}

.qrCode {
  width: 100%;
  height: 100%;
}

.button {
  margin-bottom: 32px;
  width: 100%;
  border-radius: 50px;
}

.copySecret {
  background-color: rgb(23, 22, 22);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 10px 20px;
  width: fit-content;
  border-radius: 10px;
  margin-inline: auto;
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .card {
    padding-left: 0;
  }
}

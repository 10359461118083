.password {
	margin-block: 45px 55px;
	margin-bottom: 30px;
	animation: var(--fadeInAndScrollUp) 0.5s ease;
}

.subtitle {
	font-size: 15px;
	color: var(--auth-text-secondary);
	margin-bottom: 30px;
	font-weight: 500;
}

.box {
	padding: 26px 30px 31px 30px;
	border-radius: 8px;
	border: 1px solid #ededed;
}

.box h5 {
	font-weight: 600;
	margin-bottom: 15px;
}

.buttons {
	margin-left: auto;
	width: 183px;
}

.save {
	width: 100%;
	padding: 16px;
	border-radius: 39px;
}

.inputs {
	max-width: 590px;
}

.input {
	padding-right: 35px;
}

.label {
	font-weight: 400;
	font-size: 14px;
	color: #242625;
	margin-bottom: 12px;
}

.card {
	width: 100%;
	padding-inline: 10px;
	max-width: 600px;
	margin-inline: auto;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loaderBox {
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	gap: 0;
}
.rotate {
	animation: rotation 2s infinite linear;
}
.fixed {
	transform: translateX(-20px);
}

@keyframes rotation {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(359deg);
	}
}

.errorPopup {
	text-align: center;
}

.errorPopup p {
	margin-block: 20px 30px;
}

.button {
	width: 100%;
	max-width: 150px;
}

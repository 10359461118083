.kyc {
	margin-block: 45px 55px;
	margin-bottom: 30px;
	animation: var(--fadeInAndScrollUp) 0.5s ease;
}

.box {
	padding: 26px 30px 31px 30px;
	border-radius: 8px;
	border: 1px solid #ededed;
}

.box h5 {
	font-weight: 600;
	margin-bottom: 31px;
}

.info,
.infoApproved {
	margin-bottom: 50px;
	color: var(--auth-text-secondary);
	transition: all 0.5s ease;
	font-weight: 500;
}

.infoApproved {
	margin-bottom: 0;
	color: var(--warning-dark);
}

.button {
	border-radius: 30px;
	width: 100%;
	max-width: 200px;
}

.date {
	margin-top: 5px;
}
